<template>
    <div>
        <div>
            <h6>New Company Projects</h6>
        </div>
        <company-table :company="company" v-for="company,index in companies" :key="index"></company-table>
    </div>
</template>

<script>
import CompanyTable from "./component/company-table.vue";
export default {
    props: ["companies"],
    components: {
        CompanyTable
    },

    data() {
        return {

        }
    }
}
</script>

<style >

</style>