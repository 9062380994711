<template>
    <router-link :to="`/project-info-v2?id=${project.project_id}`">
        <div class="row project-custom-table">
            <div class="col-xl-4">
                Name: {{ project.name_en }}
            </div>
            <div class="col-xl-4">
                BCC: {{ project.project_id }}
            </div>
            <div class="col-xl-4">
                Submitted: {{ moment(project.createdAt).format("LL") }}
            </div>

        </div>
    </router-link>
</template>

<script >
export default {

    props: ["project"],


}

</script>

<style >
.project-custom-table {
    margin: 10px 0px;
    padding: 20px 10px;
    border-radius: 5px;
    color: #000;
    background-color: #fff;
}
</style>