<template>
  <div v-if="experts">
    <div>
      <h6>Pending Experts Requests</h6>
    </div>

    <expert-table
      :expert="expert"
      v-for="(expert, index) in experts"
      :key="index"
      @loadPendingExperts="reloadData"
    ></expert-table>
  </div>
</template>

<script>
import ExpertTable from "./component/expert-table.vue";
export default {
  props: ["experts"],
  components: {
    ExpertTable,
  },
  methods: {
    reloadData() {
      this.$emit("loadPendingExperts");
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
