<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import ProjectPending from "./project-pending.vue";
import CompanyPending from "./companies-pending.vue";
import ExpertPending from "./experts-pending.vue";
import { projects } from "@/config/api/projects";
import { users } from "@/config/api/users";
import { companies } from "@/config/api/companies";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    ProjectPending,
    CompanyPending,
    ExpertPending,
  },
  data() {
    return {
      pending_projects: [],
      pending_experts: [],
      pending_companies: [],
      title: "Dashboard",
      items: [
        {
          text: "Mawj",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  methods: {
    loadPendingProjects() {
      let api = projects.model.get;
      let params = {
        page: 1,
        limit: 30,
        is_approved: false,
      };
      api.params = params;
      this.generateAPI(api).then((res) => {
        this.pending_projects = res.data.projects.docs;
      });
    },
    loadPendingCompany() {
      let api = companies.get;
      let params = {
        page: 1,
        limit: 30,
        is_company_approved: false,
      };
      api.params = params;
      this.generateAPI(api).then((res) => {
        this.pending_companies = res.data.companies.docs;
      });
    },
    loadPendingExperts() {
      let api = users.pendingExpertRequest;

      this.generateAPI(api).then((res) => {
        this.pending_experts = res.data.requests;
      });
    },
  },
  mounted() {
    this.loadPendingProjects();
    this.loadPendingCompany();
    this.loadPendingExperts();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Stat />
        <RevenueAnalytics />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <expert-pending
          :experts="pending_experts"
          @loadPendingExperts="loadPendingExperts"
        ></expert-pending>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <company-pending :companies="pending_companies"></company-pending>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <project-pending :projects="pending_projects"></project-pending>
      </div>
    </div>
  </Layout>
</template>
