export const analytics = {
  getTotalNumberOfProjects: {
    url: "analytics/getTotalNumberOfProjects",
    method: "GET",
    data: null,
  },
  getTotalNumberOfUsers: {
    url: "analytics/getTotalNumberOfUsers",
    method: "GET",
    id: null,
    data: null,
  },
  getAnalytics: {
    url: "analytics",
    method: "GET",
    data: null,
  },
  getGraphedNumberOfUsers: {
    url: "analytics/getNumberOfUsers",
    method: "GET",
    id: null,
    params: null,
  },

  getGraphedNumberOfCompanies: {
    url: "analytics/graph/getTotalNumberOfCompanies",
    method: "GET",
    id: null,
    params: null,
  },
  getGraphedNumberOfExperts: {
    url: "analytics/graph/getTotalNumberOfExperts",
    method: "GET",
    id: null,
    params: null,
  },
  getGraphedNumberOfViews: {
    url: "analytics/graph/getTotalNumberOfProjectViews",
    method: "GET",
    id: null,
    params: null,
  },

  getGraphedNumberOfProjects: {
    url: "analytics/graph/getTotalNumberOfProjects",
    method: "GET",
    id: null,
    params: null,
  },

  getDailyGraphNumberOfCompanies: {
    url: "analytics/dailyGraphCompanies",
    method: "GET",
    id: null,
    params: null,
  },
  getDailyGraphNumberOfUsers: {
    url: "analytics/dailyGraphUsers",
    method: "GET",
    id: null,
    params: null,
  },
  getDailyGraphNumberOfViews: {
    url: "analytics/dailyGraphProjectsViews",
    method: "GET",
    id: null,
    params: null,
  },
  getDailyGraphNumberOfProjects: {
    url: "analytics/dailyGraphProjects",
    method: "GET",
    id: null,
    params: null,
  },
  getDailyGraphNumberOfExperts: {
    url: "analytics/dailyGraphExperts",
    method: "GET",
    id: null,
    params: null,
  },
};
