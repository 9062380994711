<script>
/**
 * Revenue Analytics component
 */
import { analytics } from "@/config/api/analytics";

export default {
  mounted() {
    this.getGraphData();
    // this.getDailyGraph();
  },
  methods: {
    async getGraphData() {
      try {
        const labels = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var api;
        var analytic_type = this.$store.state.dashboard.analytics;
        if (analytic_type == "Total number of users") {
          api = analytics.getGraphedNumberOfUsers;
        } else if (analytic_type == "Number of projects") {
          api = analytics.getGraphedNumberOfProjects;
        } else if (analytic_type == "Total number of companies") {
          api = analytics.getGraphedNumberOfCompanies;
        } else if (analytic_type == "Total number of views") {
          api = analytics.getGraphedNumberOfViews;
        } else if (analytic_type == "Total number of experts") {
          api = analytics.getGraphedNumberOfExperts;
        }

        var res = await this.generateAPI(api);
        var allYears = res.data;
        var thisYear = 0;
        var lastYear = 0;
        for (var year in allYears) {
          var dataArr = Array.from({ length: 12 }, () => 0);
          for (var month in Object.keys(allYears[year])) {
            var index = Object.keys(allYears[year])[month] - 1;
            if (
              year == new Date().getFullYear() &&
              index == new Date().getMonth()
            ) {
              this.thisMonth =
                allYears[year][Object.keys(allYears[year])[month]];
              // Will get the value of the previous month. In case this is January (first month)
              // It will check for the last month of the previous year.
              this.pastMonth =
                allYears[parseInt(month) !== 0 ? year : year - 1][
                  Object.keys(allYears[year])[
                    parseInt(month) !== 0
                      ? month - 1
                      : Object.keys(allYears[year]).length - 1
                  ]
                ];
            }
            if (
              year == new Date().getFullYear()
              // Object.keys(allYears[year])[month] - 1 == new Date().getMonth()
            ) {
              thisYear =
                allYears[year][Object.keys(allYears[year])[month]] + thisYear;
            }
            if (
              year ==
              new Date().getFullYear() - 1
              // Object.keys(allYears[year])[month] - 1 == new Date().getMonth()
            ) {
              lastYear =
                allYears[year][Object.keys(allYears[year])[month]] + thisYear;
            }
            this.lastYear = lastYear;
            var indexValue = allYears[year][Object.keys(allYears[year])[month]];
            dataArr[index] = indexValue;
          }
          this.thisYear = thisYear;
          this.series = [{
            name: year,
            type: "column",
            data: dataArr,
          }];
          this.chartOptions = { labels: labels };
        }
      } catch (e) {
        console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
        console.log("Error happened");
        console.log(e);
        console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
      }
    },

    async getDailyGraph() {
      var api;
      var analytic_type = this.$store.state.dashboard.analytics;
      if (analytic_type == "Total number of users") {
        api = analytics.getDailyGraphNumberOfUsers;
      } else if (analytic_type == "Number of projects") {
        api = analytics.getDailyGraphNumberOfProjects;
      } else if (analytic_type == "Total number of companies") {
        api = analytics.getDailyGraphNumberOfCompanies;
      } else if (analytic_type == "Total number of views") {
        api = analytics.getDailyGraphNumberOfViews;
      } else if (analytic_type == "Total number of experts") {
        api = analytics.getDailyGraphNumberOfExperts;
      }

      var res = await this.generateAPI(api);
      var data = res.data.data;
      this.series =[{
        name: "Past 30 Days",
        type: "column",
        data: data.data,
      }];
      this.chartOptions = { labels: data.labels };
    },

    changeGraph(typ) {
      if (typ == "daily") {
        if (!this.isDaily) {
          this.getDailyGraph();
          this.isDaily = true;
          this.isMonth = false;
        }
      } else if (typ == "monthly") {
        if (!this.isMonth) {
          this.getGraphData();
          this.isDaily = false;
          this.isMonth = true;
        }
      }
    },
    changeGraphCategory() {
      if (this.isDaily) {
        this.getDailyGraph();
      }
      if (this.isMonth) {
        this.getGraphData();
      }
    },
  },
  data() {
    return {
      series: [],
      // bottomStat: true,
      isMonth: true,
      isDaily: false,
      thisYear: 0,
      lastYear: 0,
      thisMonth: null,
      pastMonth: null,
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    };
  },
  watch: {
    "$store.state.dashboard.analytics": function () {
      this.changeGraphCategory();
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right d-none d-md-inline-block">
        <div class="btn-group mb-2">
          <!-- <button type="button" class="btn btn-sm btn-light">Today</button> -->
          <button
            type="button"
            class="btn btn-sm btn-light"
            :class="{ active: isDaily }"
            @click="changeGraph('daily')"
          >
            Daily
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            :class="{ active: isMonth }"
            @click="changeGraph('monthly')"
          >
            Monthly
          </button>
        </div>
      </div>
      <h4 class="card-title mb-4">{{ $store.state.dashboard.analytics }}</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center" v-if="isMonth">
      <div class="row">
        <div class="col-sm-4">
          <div class="d-inline-flex">
            <h5 class="mr-2">{{ this.thisMonth }}</h5>
            <div
              :class="
                this.thisMonth - this.pastMonth >= 0
                  ? 'text-success'
                  : 'text-danger'
              "
            >
              <i
                :class="
                  this.thisMonth - this.pastMonth >= 0
                    ? 'mdi mdi-menu-up font-size-14'
                    : 'mdi mdi-menu-down font-size-14'
                "
              ></i
              >{{
                (
                  ((this.thisMonth - this.pastMonth) / this.pastMonth) *
                  100
                ).toFixed(2)
              }}
              %
            </div>
          </div>
          <p class="text-muted text-truncate mb-0">This month</p>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This
              Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">{{ this.thisYear }}</h5>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"></i>2.1 %
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
              Previous Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">{{ lastYear }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>