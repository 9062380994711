<template>
    <router-link :to="`/company-info?id=${company._id}`">
        <div class="row project-custom-table">
            <div class="col-xl-4">
               {{ company.display_name }} - ( {{ company.legal_name }} )
            </div>
            <div class="col-xl-4"> 
                Email: {{company.company_email}}
            </div>
            <div class="col-xl-3">
                Created On : {{ moment(company.createdAt).format("LL") }}
            </div>

        </div>
    </router-link>
</template>

<script >
export default {

    props: ["company"],


}

</script>

<style >
.project-custom-table {
    margin: 10px 0px;
    padding: 20px 10px;
    border-radius: 5px;
    color: #000;
    background-color: #fff;
}
</style>