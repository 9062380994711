<template>
    <div>
        <div>
            <h6>New Pending Projects</h6>
        </div>
        <project-table :project="project" v-for="project,index in projects" :key="index"></project-table>
    </div>
</template>

<script>
import ProjectTable from "./component/project-table.vue";
export default {
    props: ["projects"],
    components: {
        ProjectTable
    },

    data() {
        return {

        }
    }
}
</script>

<style >

</style>