<script>
/**
 * Widget Component
 */
import { analytics } from "@/config/api/analytics";
export default {
  // created:{

  // }
  async mounted() {
   
    await this.getAnalytics();
  },
  methods: {
    async getAnalytics() {
      this.loading = true;
      let api = analytics.getAnalytics;
      var res = await this.generateAPI(api);
      var data = [
        {
          title: "Number of projects",
          icon: "ri-stack-line",
          value: res.data.projects,
        },
        {
          title: "Total number of users",
          icon: "ri-briefcase-4-line",
          value: res.data.users,
        },
        {
          title: "Total number of companies",
          icon: "ri-briefcase-4-line",
          value: res.data.companies,
        },
        {
          title: "Total number of experts",
          icon: "ri-briefcase-4-line",
          value: res.data.experts,
        },
        {
          title: "Total number of regions",
          icon: "ri-briefcase-4-line",
          value: res.data.regions,
        },
        {
          title: "Total number of views",
          icon: "ri-briefcase-4-line",
          value: res.data.views,
        },
      ];
      this.statData= data;
      console.log(this.statData);
    },
    selectAnalytics(analytics) {
      this.$store.dispatch("dashboard/selectAnalytics", analytics);
    },
  },
  data() {
    return {
      // loading: false,
      statData: [
        // {
        //   title: "Number of Projects",
        //   icon: "ri-stack-line",
        //   value: "1452",
        // },
        // {
        //   title: "Average Price",
        //   icon: "ri-briefcase-4-line",
        //   value: "$ 15.4",
        //   subvalue: " 2.4% ",
        // },
      ],
    };
  },
};
</script>

<template>
  <div class="row" v-if="!statData.length == 0">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div
        class="card"
        @click="selectAnalytics(data.title)"
        style="cursor: pointer"
      >
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-store-2-line font-size-24`"></i>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Loading Title</p>
              <h4 class="mb-0">Loading data</h4>
            </div>
            <div class="text-primary">
              <i :class="`ri-briefcase-4-line font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>